import React from "react";
import user from "../assets/user.jpeg";
import { Fade, Zoom } from "react-awesome-reveal";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import Spinner from "../Components/Spinner";

const BlogPost = () => {
  const { blogId } = useParams();

  const GET_SINGLEBLOGPOST = gql`
	query GetSingleBlogPost {
		articles (id: "${blogId}") {
		banner {
		  url
		}
		title
		headline
		story {
			json 
			links {
			  entries {
				block {
				  __typename
				}
				inline {
				  sys {
					id
				  }
				}
			  }
			  assets {
				block {
				  sys {
					id
				  }
				  url
				  contentType
				}
			  }
			}
		  }
		author
		aboutAuthor
		authorsDisplayPicture {
		  url
		}
	  }
	  articlesCollection (limit: 10) {
		items {
		  sys {
			id
			publishedAt
		  }
		  banner {
			url
		  }
		  title
		  headline
		  author
		}
	  }
	}
	`;

  const SUBMIT_COMMENT = gql`
    mutation SubmitComment(
      $articlesId: String!
      $name: String!
      $email: String!
      $website: String!
      $message: String!
    ) {
      createComments(
        data: {
          name: $name
          email: $email
          website: $website
          message: $message
          articles: { connect: { id: $articlesId } }
        }
      ) {
        _id
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_SINGLEBLOGPOST);
  const [submitComment] = useMutation(SUBMIT_COMMENT);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await submitComment({
        variables: {
          articlesId: blogId,
          name: event.target.elements.name.value,
          email: event.target.elements.email.value,
          website: event.target.elements.website.value,
          message: event.target.elements.message.value,
        },
      });
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const renderOptions = (links) => {
    const assetMap = new Map();

    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }

    // create an entry block map
    const entryBlockMap = new Map();

    for (const entry of links.entries.block) {
      entryBlockMap.set(entry.sys.id, entry);
    }

    // render marks
    return {
      renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
        [MARKS.UNDERLINE]: (text) => <span>{text}</span>,
        [MARKS.ITALIC]: (text) => <i>{text}</i>,
        [MARKS.CODE]: (text) => <code>{text}</code>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children) => {
          return <p className="story">{children}</p>;
        },

        [BLOCKS.HEADING_1]: (_node, children) => {
          return <h1>{children}</h1>;
        },

        [BLOCKS.HEADING_2]: (_node, children) => {
          return <h2>{children}</h2>;
        },

        [BLOCKS.HEADING_3]: (_node, children) => {
          return (
            <h3
              style={{
                fontSize: "20px",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              {children}
            </h3>
          );
        },

        [BLOCKS.HEADING_4]: (_node, children) => {
          return (
            <h4
              style={{
                fontSize: "18px",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              {children}
            </h4>
          );
        },

        [BLOCKS.HEADING_5]: (_node, children) => {
          return <h5>{children}</h5>;
        },

        [BLOCKS.HEADING_6]: (_node, children) => {
          return <h6>{children}</h6>;
        },

        [INLINES.HYPERLINK]: ({ data }, children) => (
          <a
            className="hyperLinks"
            href={data.uri}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        ),

        [BLOCKS.UL_LIST]: (_node, children) => (
          <ul>
            <li>{children}</li>
          </ul>
        ),

        [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,

        [BLOCKS.LIST_ITEM]: (_node, children) => (
          <li style={{ fontSize: "16px" }}>{children}</li>
        ),

        [BLOCKS.EMBEDDED_ASSET]: (node, _next) => {
          const asset = assetMap.get(node.data.target.sys.id);

          return (
            <div className="secondaryImgCon">
              <img src={asset.url} alt="asset-img" />
            </div>
          );
        },
      },
    };
  };

  if (loading)
    return (
      <p>
        <Spinner />
      </p>
    );
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div>
      <section className="">
        <div
          className="services-background-image helping-business"
          style={{ backgroundImage: `url(${data?.articles.banner.url})` }}
        >
          <Fade direction="down" triggerOnce={true}>
            <div className="container">
              <h1 className="w-75">{data?.articles.title}</h1>
            </div>
          </Fade>
        </div>
      </section>

      <section className="datablog-post-main-container">
        <div className="datablog-post-container container d-flex">
          <div className="datablog-post-header">
            <div className="datablog-post-image-header helping-business-achieve-data container">
              <Fade direction="up" triggerOnce={true}>
                <div>
                  {documentToReactComponents(
                    data?.articles?.story.json,
                    renderOptions(data?.articles?.story.links)
                  )}
                </div>
              </Fade>
            </div>
          </div>
          <div>
            <Fade direction="right" triggerOnce={true}>
              <div className="recent-post container">
                <div>
                  <h3>Recent Posts</h3>
                </div>
                {data.articlesCollection.items
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.sys.publishedAt) - new Date(a.sys.publishedAt)
                  )
                  .slice(0, 3)
                  .map((article) => (
                    <div className="d-flex" key={article.sys.id}>
                      <div>
                        <img src={article.banner.url} alt="" />
                      </div>
                      <div className="ms-3">
                        <a href={`/blog/${article.sys.id}`}>
                          <h6>{article.title}</h6>
                        </a>
                        <p>
                          {new Date(article.sys.publishedAt).toLocaleDateString(
                            "en-GB"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </Fade>
          </div>
        </div>
        <div className="d-flex container about-author mt-5">
          <div className="">
            <img src={user} alt="" />
          </div>
          <div>
            <h2>{data?.articles.author}</h2>
            <p>{data?.articles.aboutAuthor}</p>
          </div>
        </div>

        <div className="container mb-5 pb-5 leave-reply">
          <div>
            <Fade direction="down" triggerOnce={true}>
              <h1 className="mb-4">Leave a Reply</h1>
              <p>
                Your email address will not be published. Required fields are
                marked *
              </p>
            </Fade>
          </div>
          <Zoom triggerOnce={true}>
            <form onSubmit={handleSubmit}>
              <div className="form-group col-8">
                <label htmlFor="comments" className="mb-2 label">
                  Comments <span className="required">* (required)</span>
                </label>
                <textarea
                  rows="8"
                  className="form-control"
                  name="message"
                  placeholder="Input text"
                  required
                ></textarea>
              </div>
              <div className="form-group col-8">
                <label htmlFor="first_name" className="mb-2 label">
                  Name <span className="required">* (required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Input Name"
                  required
                />
              </div>
              <div className="form-group col-8">
                <label htmlFor="email" className="mb-2 label">
                  Email <span className="required">* (required)</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Input Valid Email Address"
                  required
                />
              </div>

              <div className="form-group col-8">
                <label htmlFor="email" className="mb-2 label">
                  Website <span className="required">* (required)</span>
                </label>
                <input
                  type="url"
                  className="form-control"
                  name="website"
                  required
                  placeholder="Input"
                />
              </div>
              <input
                type="submit"
                className="btn btn-primary mt-4 post-btn"
                value="Post Comment"
              />
            </form>
          </Zoom>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogPost;
