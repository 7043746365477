import React from "react";
import img8 from "../assets/image.png";
import main from "../assets/databrain_man1.855c5ded3e93988bc641.png.png";
import { Fade, Zoom } from "react-awesome-reveal";
import stat from "../assets/image-1.png";
import sales from "../assets/Stats03.2d00c756a0c20d65132d.png.png";
import Footer from "../Components/Footer";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../Components/Spinner";

const GET_HOMEPAGEDATA = gql`
  query GetHomePageData {
    heroSectionLandingPage(id: "6u7AOlu9xaHjcKhdX6rC9w") {
      companyName
      slogan
      aboutUs
    }
    servicesSectionHeaders(id: "5PB0lB2UyDi0QYdLikPHJv") {
      heading
      subHeading
      whatWeDo
    }
    servicesCollection(limit: 6) {
      items {
        sys {
          id
        }
        heading
        subHeading
        whatWeDo
      }
    }
    analyticsSectionHeader(id: "5SMMoaky46sjwB5h9jyArE") {
      heading
      subHeading
      analyticsSectionImage {
        url
      }
    }
    analyticsCollection(limit: 3) {
      items {
        sys {
          id
        }
        figure
        details
      }
    }
    weHaveGotYouCovered(id: "1RitCjbHdxw2iyvNlgSXLp") {
      sectionTitle
      heading
      headline
      firstPointer
      secondPointer
      thirdPointer
      homePageWhgvcImage {
        url
      }
    }
    blogSectionHeadersLandingPage(id: "32pD2Z6TpGZX8m12QQ3V7S") {
      heading
      subHeading
    }
    articlesCollection(limit: 3, order: sys_publishedAt_DESC) {
      items {
        sys {
          id
        }
        title
        banner {
          url
        }
        headline
        author
      }
    }
  }
`;

const Home = () => {
  const { loading, error, data } = useQuery(GET_HOMEPAGEDATA);

  if (loading) return <Spinner />;
  if (error) return <p>Error : {error.message}</p>;

  function getDirection(index) {
    const directions = ["left", "down", "right", "up", "left", "down"];
    return directions[index % directions.length];
  }

  return (
    <div className="">
      <section>
        <div className="background-image">
          <div className="container d-flex">
            <div>
              <Zoom direction="up" triggerOnce={true}>
                <img src={main} alt="" />
              </Zoom>
              <div className="home-img1">
                <Zoom triggerOnce={true}>
                  <img src={img8} alt="" />
                </Zoom>
              </div>
              <div className="home-img2">
                <Zoom triggerOnce={true}>
                  <img src={stat} alt="" />
                </Zoom>
              </div>
              <div className="home-img3">
                <Zoom triggerOnce={true}>
                  <img src={sales} alt="" />
                </Zoom>
              </div>
            </div>
            <div className="background-image-header">
              <Fade direction="down" triggerOnce={true}>
                <div className="d-flex mb-3">
                  <h6>{data?.heroSectionLandingPage.companyName}</h6>
                </div>
                <div>
                  <h1>{data?.heroSectionLandingPage.slogan}</h1>
                  <p>{data?.heroSectionLandingPage.aboutUs}</p>
                </div>
              </Fade>
              <div className="home-btn-header d-flex">
                <Fade direction="down" triggerOnce={true}>
                  <div className="home-btn mt-3">
                    <a href="/About">
                      <button>About Us</button>
                    </a>
                  </div>
                  <div className="home-btn-2 mt-3">
                    <a href="/Contact">
                      <button>Contact Us</button>
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="background-image-2">
          <div className="container">
            {/* <div className="d-flex services-text-header">
              <Fade direction="down" triggerOnce={true}>
                <div>
                  <div className="d-flex">
                    <h5>{data?.servicesSectionHeaders.heading}</h5>
                  </div>
                  <div>
                    <h1>{data?.servicesSectionHeaders.subHeading}</h1>
                  </div>
                </div>
                <div>
                  <p>{data?.servicesSectionHeaders.whatWeDo}</p>
                </div>
              </Fade>
            </div> */}
            <div className="background-header d-flex pt-4">
              {data?.servicesCollection.items.map((service, index) => (
                <div className="background" key={service?.sys.id}>
                  <Fade direction={getDirection(index)} triggerOnce={true}>
                    <div className="d-flex">
                      <div className="background-number">
                        <h6>{index + 1}</h6>
                      </div>
                      <div>
                        <h3>{service?.heading}</h3>
                        <h4>{service?.subHeading}</h4>
                        <p>{service?.whatWeDo}</p>
                      </div>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="home-section-attachment-header">
        <div className="container d-flex">
          <div className="growing-demand-data-analytics-header">
            <Fade direction="left" triggerOnce={true}>
              <div className="d-flex mb-4">
                <h5>{data?.analyticsSectionHeader.heading}</h5>
              </div>
              <div className="mb-5">
                <h1>{data?.analyticsSectionHeader.subHeading}</h1>
              </div>
            </Fade>
            {data?.analyticsCollection.items.map((item, index) => (
              <Fade
                direction="left"
                delay={400 + index * 200}
                triggerOnce={true}
                key={item.sys.id}
              >
                <div className="d-flex mt-5 growing-demand-data-analytics-header-number">
                  <h2>{item?.figure}</h2>
                  <p>{item?.details}</p>
                </div>
              </Fade>
            ))}
          </div>
          <div className="home-attachment-header">
            <div className="home-attachment">
              <Fade direction="right" triggerOnce={true}>
                <img
                  src={data?.analyticsSectionHeader.analyticsSectionImage.url}
                  alt=""
                />
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="services-social">
          <div className="services-social-header">
            <div className="mb-4">
              <h5>{data?.weHaveGotYouCovered.sectionTitle}</h5>
            </div>
            <div>
              <h1 className="mb-3">{data?.weHaveGotYouCovered.heading}</h1>
              <p className="mb-5">{data?.weHaveGotYouCovered.headline}</p>
            </div>
            <div className="d-flex">
              <i className="fas fa-chevron-circle-right mt-1 me-3"></i>
              <p>{data?.weHaveGotYouCovered.firstPointer}</p>
            </div>
            <div className="d-flex">
              <i className="fas fa-chevron-circle-right mt-1 me-3"></i>
              <p>{data?.weHaveGotYouCovered.secondPointer}</p>
            </div>
            <div className="d-flex">
              <i className="fas fa-chevron-circle-right mt-1 me-3"></i>
              <p>{data?.weHaveGotYouCovered.thirdPointer}</p>
            </div>
          </div>
        </div>
        <div className="services-social-image-header">
          <img src={data?.weHaveGotYouCovered.homePageWhgvcImage.url} alt="" />
        </div>
      </section>

      <section className="home-background-image4">
        <div className="container home-articles-header d-flex">
          <Fade direction="down" triggerOnce={true}>
            <div className="mb-5">
              <div className="d-flex mb-2">
                <h6>{data?.blogSectionHeadersLandingPage.heading}</h6>
              </div>
              <div>
                <h1>{data?.blogSectionHeadersLandingPage.subHeading}</h1>
              </div>
            </div>
            <div>
              <a href="/DataBlog">
                <button>Discover More</button>
              </a>
            </div>
          </Fade>
        </div>
        <div className="home-articles container">
          {data?.articlesCollection.items.map((article, index) => {
            const fadeDirection =
              index % 3 === 0 ? "left" : index % 3 === 1 ? "down" : "right";
            return (
              <Fade
                direction={fadeDirection}
                triggerOnce={true}
                key={article.sys.id}
              >
                <div className="home-articles-post">
                  <div>
                    <img src={article.banner.url} alt={article.title} />
                    <h6 className="mb-3">{article.title}</h6>
                    <div>
                      <i aria-hidden="true" className="fas fa-user me-2"></i>
                      <span>{article.author}</span>
                    </div>
                    <div>
                      <p className="mt-2 truncated-multiline">
                        {article.headline}
                      </p>
                    </div>

                    <div>
                      <a href={`/blog/${article.sys.id}`}>
                        <button>
                          Read more
                          <i
                            aria-hidden="true"
                            className="fas fa-arrow-right ms-2"
                          ></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
